import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BarChart from './Feedback/components/BarChart';
import DataMatrix from './Feedback/DataMatrix';
import { DeviceScreenshots } from './Feedback/DeviceScreenshots';
import { InterviewAssessment } from './Feedback/InterviewAssessment';
import { ScreeningQuestionsv2 } from './Feedback/ScreeningQuestionsv2';
import { ScreeningQuestionsV3 } from './Feedback/ScreeningQuestionsV3';
import { SoftSkillAssessment } from './Feedback/SoftSkillAssessment';
import { getInterview } from '../redux/action';
import {
    FinalRemarks,
    Footer,
    Header,
    OverallSkills,
    Proctoring,
    Profile,
    ScreeningQuestions,
    Screenshots,
} from './Feedback';
import FullPageProgress from './FullPageProgress';
import { capitalizeAll } from '../utils/helper';
import '../assets/css/feedback.css';
import '../assets/fonts/stylesheet.css';

const Other = React.lazy(() => import('./Pages/Other'));
function FeedbackRoom() {
    const { roomId, interviewId } = useParams();
    const dispatch = useDispatch();
    const { loading, interview, whiteLabelLogos } = useSelector((state) => state.Interview);
    const [showProctoringLogs, setShowProctoringLogs] = useState(true);
    const [showSoftSkills, setShowSoftSkills] = useState({
        Fluency: true,
        Clarity: true,
        Grammar: true,
        Confidence: true,
    });
    const [showFinalRemarks, setShowFinalRemarks] = useState({
        Strengths: true,
        Weaknesses: true,
        'Communication Skills': true,
        'Grammatical Correctness': true,
        Clarity: true,
        Conclusion: true,
    });
    const [profileForm, setProfileForm] = useState({
        image: '',
        code: '',
        name: '',
        position: '',
        company: '',
        email: '',
        phone: '',
        interviewDate: '',
        status: '',
        link: '',
    });
    const [feedbackForm, setFeedbackForm] = useState({
        duration: 0,
        codingQuestionStats: null,
        positionSpecificQuestionStats: null,
        aptitudeSpecificQuestionStats: null,
        finalPercentileValue: 0,
        totalTimeTaken: 0,
        totalParticipants: 0,
        totalDuration: 0,
        resumeSummary: '',
        overallFeedback: null,
        interviewAssessment: null,
        softSkillAssessment: null,
        questions: {
            status: false,
            data: [],
        },
        finalRemarks: {
            status: false,
            data: '',
        },
        proctoringLogs: {
            status: false,
            messages: [],
            screenshots: [],
        },
        screenshots: {
            status: false,
            images: [],
        },
        deviceScreenshots: {
            status: false,
            images: [],
        },
        score: null,
        userSettings: null,
    });
    const [totalAptitudeTime, setTotalAptitudeTime] = useState(0);
    useEffect(() => {
        if (profileForm?.name) {
            document.title = `${capitalizeAll(profileForm?.name)} Feedback Report`;
        }
    }, [profileForm]);
    useEffect(() => {
        if (roomId && interviewId && !interview) {
            dispatch(getInterview({ roomId: roomId, interviewId: interviewId }));
        } else if (roomId && interview) {
            if (interview?.candidate) {
                setProfileForm((prevState) => ({
                    ...prevState,
                    code: interview?.code,
                    name: interview?.candidate?.name,
                    email: interview?.candidate?.email,
                    phone: `+${interview?.candidate?.phone}`,
                    interviewDate: interview?.interviewStartDate,
                    position: interview?.position?.title,
                    company: interview?.employer?.name ?? 'InCruiter',
                    status: interview?.status,
                    finalRating: interview?.finalRating ?? 0,
                    link: whiteLabelLogos?.logo || '',
                    image: interview?.feedback?.candidateImage,
                }));
            }
            if (interview?.feedback) {
                let suspiciousRating;
                // eslint-disable-next-line no-prototype-builtins
                if (interview?.feedback?.overAllRating?.hasOwnProperty('suspiciousActivity')) {
                    suspiciousRating = interview?.feedback?.overAllRating?.suspiciousActivity || 0;
                } else {
                    const searchText = 'Candidate switched to another tab';
                    const containsSwitchedText = interview?.feedback?.proctoring?.messages?.some((message) =>
                        message.text.includes(searchText)
                    );
                    const containsLookingAway = interview?.feedback?.proctoring?.messages?.some((message) =>
                        message.text.includes('Looking away from screen')
                    );
                    suspiciousRating = containsSwitchedText ? 10 : containsLookingAway ? 5 : 0;
                }
                let finalQuestionList = [];
                if (interview.question.questions && interview?.feedback?.questions) {
                    (interview.questions.length > 0 ? interview.questions : interview.question.questions)
                        .filter((itm) => itm?.question !== '')
                        .forEach((ques) => {
                            const normalizeText = (text) => text.replace(/\s+/g, '').replace(/\r?\n|\r/g, '');
                            const itmQuestion = normalizeText(ques.question);
                            const foundQuestion = interview?.feedback?.questions.find((finalItem) => {
                                const finalQuestion = normalizeText(finalItem?.question);
                                return itmQuestion === finalQuestion;
                            });
                            if (ques?.codeBlock) foundQuestion.codeBlock = ques?.codeBlock;
                            if (foundQuestion) {
                                finalQuestionList.push(foundQuestion);
                            } else {
                                finalQuestionList.push({
                                    question: ques?.question,
                                    answer: "The candidate didn't answer.",
                                    timeTaken: 0,
                                    rating: 0,
                                    questionType: 'custom',
                                });
                            }
                        });
                }
                let totalDuration = parseInt(interview?.question?.totalDuration || 0);
                interview?.feedback?.questions?.forEach((question) => {
                    question?.conversationAIQuestions?.forEach(() => {
                        totalDuration += 65;
                    });
                });
                setFeedbackForm((prevState) => ({
                    ...prevState,
                    duration: interview?.totalDuration,
                    totalDuration: totalDuration,
                    codingQuestionStats: interview?.codingQuestionStats,
                    positionSpecificQuestionStats: interview?.positionSpecificQuestionStats,
                    aptitudeSpecificQuestionStats: interview?.aptitudeSpecificQuestionStats,
                    finalPercentileValue: interview?.finalPercentileValue,
                    totalTimeTaken: interview?.totalTimeTaken,
                    totalParticipants: interview?.totalParticipants,
                    version: interview?.feedback?.feedbackVersion || 1,
                    resumeSummary: interview?.feedback?.resumeSummary
                        ? interview?.feedback?.resumeSummary
                              .replace(/\\r/g, '')
                              .replace(/\\n/g, '')
                              .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                              .replace(/\\s/g, '&nbsp;')
                              .replace('<br /><br />', '')
                        : '',
                    questions: {
                        status: finalQuestionList.length > 0 ? true : false,
                        data: finalQuestionList ?? [],
                    },
                    proctoringLogs: {
                        status: interview?.feedback?.proctoring ? true : false,
                        messages: interview?.feedback?.proctoring?.messages ?? [],
                        screenshots: interview?.feedback?.proctoring?.screenshots ?? [],
                    },
                    finalRemarks: {
                        status: interview?.feedback?.remark ?? true,
                        data:
                            interview?.feedback?.remark ??
                            'Unfortunately, we couldn’t generate final remarks as not all the questions were answered.',
                    },
                    screenshots: {
                        status: interview?.feedback?.screenshots
                            ? interview?.feedback?.screenshots.length > 0
                                ? true
                                : false
                            : false,
                        images: interview?.feedback?.screenshots ?? [],
                    },
                    deviceScreenshots: {
                        status: interview?.feedback?.deviceScreenshots
                            ? interview?.feedback?.deviceScreenshots.length > 0
                                ? true
                                : false
                            : false,
                        images: interview?.feedback?.deviceScreenshots ?? [],
                    },
                    score: interview?.feedback?.overAllRating?.score || null,
                    overallFeedback: [
                        {
                            title: interview?.feedback?.feedbackVersion > 3 ? 'Answer Accuracy' : 'Interview Score',
                            value: interview?.feedback?.overAllRating?.interviewScore,
                        },
                        {
                            title: 'Resume Rating',
                            value: interview?.feedback?.overAllRating?.resumeRating,
                        },
                        {
                            title: 'Suspicious Activity',
                            value: suspiciousRating,
                        },
                    ],
                    softSkillAssessment: [
                        {
                            title: 'Fluency',
                            value: interview?.feedback?.interviewAssessment?.fluency || 0,
                        },
                        {
                            title: 'Clarity',
                            value: interview?.feedback?.interviewAssessment?.clarity || 0,
                        },
                        {
                            title: 'Grammar',
                            value: interview?.feedback?.interviewAssessment?.grammar || 0,
                        },
                        {
                            title: 'Confidence',
                            value: interview?.feedback?.interviewAssessment?.confidence || 0,
                        },
                    ],
                    interviewAssessment: [
                        {
                            title: 'Communication Skills',
                            value: interview?.feedback?.interviewAssessment?.communicationSkills ?? 0,
                        },
                        {
                            title: 'Fluency',
                            value: interview?.feedback?.interviewAssessment?.fluency ?? 0,
                        },
                        {
                            title: 'Confidence',
                            value: interview?.feedback?.interviewAssessment?.confidence ?? 0,
                        },
                        {
                            title: 'Answer Accuracy',
                            value: interview?.feedback?.interviewAssessment?.accuracy ?? 0,
                        },
                    ],
                    userSettings: interview?.employer?.userSettings || null,
                }));
            }
        }
    }, [dispatch, roomId, interviewId, interview]);
    const [, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (feedbackForm?.userSettings?.feedbackReportPermission) {
            const proctoringLog = feedbackForm?.userSettings?.feedbackReportPermission.find(
                (item) => item.key === 'proctoring_log'
            );
            if (proctoringLog) {
                setShowProctoringLogs(proctoringLog.status);
            }

            const softSkills = feedbackForm?.userSettings?.feedbackReportPermission.find(
                (item) => item.key === 'soft_skill_assessment'
            );
            if (softSkills) {
                const updatedSoftSkills = softSkills.resources.reduce((acc, resource) => {
                    acc[resource.name] = resource.status;
                    return acc;
                }, {});
                setShowSoftSkills(updatedSoftSkills);
            }

            const finalRemarks = feedbackForm?.userSettings?.feedbackReportPermission.find(
                (item) => item.key === 'final_remark'
            );
            if (finalRemarks) {
                const updatedFinalRemarks = finalRemarks.resources.reduce((acc, resource) => {
                    acc[resource.name] = resource.status;
                    return acc;
                }, {});
                setShowFinalRemarks(updatedFinalRemarks);
            }
        }
    }, [feedbackForm?.userSettings]);

    return loading ? (
        <FullPageProgress />
    ) : (
        <div className="App">
            {interview?.feedback ? (
                <>
                    <Header data={profileForm} />
                    <Profile
                        data={profileForm}
                        version={feedbackForm?.version}
                        duration={feedbackForm}
                        totalAptitudeTime={totalAptitudeTime}
                    />
                    <OverallSkills data={feedbackForm} version={feedbackForm?.version} />
                    {feedbackForm?.version > 4 && (
                        <>
                            <DataMatrix
                                data={feedbackForm}
                                version={feedbackForm?.version || 1}
                                totalAptitudeTime={totalAptitudeTime}
                            />
                            <BarChart data={feedbackForm} />
                        </>
                    )}
                    {feedbackForm?.interviewAssessment?.length > 0 && feedbackForm?.version < 4 && (
                        <InterviewAssessment data={feedbackForm?.interviewAssessment} version={feedbackForm?.version} />
                    )}
                    {feedbackForm?.softSkillAssessment?.length > 0 && feedbackForm?.version > 3 && (
                        <SoftSkillAssessment
                            data={feedbackForm?.softSkillAssessment}
                            version={feedbackForm?.version}
                            showSoftSkills={showSoftSkills}
                        />
                    )}
                    {feedbackForm?.finalRemarks?.status && (
                        <FinalRemarks
                            data={feedbackForm?.finalRemarks?.data}
                            version={feedbackForm?.version}
                            showFinalRemarks={showFinalRemarks}
                            finalRating={profileForm?.finalRating}
                        />
                    )}
                    {feedbackForm?.questions?.status &&
                        (feedbackForm?.version > 4 ? (
                            <ScreeningQuestionsV3
                                data={feedbackForm?.questions?.data}
                                interview={interview}
                                setTotalAptitudeTime={setTotalAptitudeTime}
                                totalAptitudeTime={totalAptitudeTime}
                                version={feedbackForm?.version || 1}
                                image={profileForm?.image}
                            />
                        ) : feedbackForm?.version > 1 ? (
                            <ScreeningQuestions data={feedbackForm?.questions?.data} />
                        ) : (
                            <ScreeningQuestionsv2 data={feedbackForm?.questions?.data} />
                        ))}
                    {feedbackForm?.proctoringLogs?.status && feedbackForm?.version < 7 && (
                        <Proctoring
                            message={feedbackForm?.proctoringLogs?.messages}
                            screenshots={feedbackForm?.proctoringLogs?.screenshots}
                        />
                    )}
                    {feedbackForm?.proctoringLogs?.status && showProctoringLogs && feedbackForm?.version >= 7 && (
                        <Proctoring
                            message={feedbackForm?.proctoringLogs?.messages}
                            screenshots={feedbackForm?.proctoringLogs?.screenshots}
                        />
                    )}
                    {feedbackForm?.screenshots?.status && <Screenshots data={feedbackForm?.screenshots?.images} />}
                    {feedbackForm?.deviceScreenshots?.status && (
                        <DeviceScreenshots data={feedbackForm?.deviceScreenshots?.images} />
                    )}
                    <Footer data={profileForm} />
                </>
            ) : (
                <Other page="404Page" />
            )}
        </div>
    );
}

export default FeedbackRoom;
